import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faLinkedin,
    faGithubSquare,
    faTwitterSquare,
    faMedium,
  } from '@fortawesome/free-brands-svg-icons'

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import './style.scss';

const WrappedIcon = ({ icon, url }) => (
  <div style={{ marginRight: 10 }}>
    <a href={url}>
      <FontAwesomeIcon icon={icon} size='3x' />
    </a>
  </div>
)

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1 className='heading__name'>Barry Peterson</h1>
    <div style={{
      display: 'flex',
      width: '100%',
      maxWidth: '400px',
      }}>

      <WrappedIcon icon={faLinkedin} url='https://www.linkedin.com/in/barrympeterson/' />
      {/* <WrappedIcon icon={faMedium} /> */}
      <WrappedIcon icon={faGithubSquare} url='https://github.com/barrypeterson' />
      <WrappedIcon icon={faTwitterSquare} url='https://twitter.com/barrypeterson_' />
    </div>
  </Layout>
)

export default IndexPage
